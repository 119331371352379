@import '../../node_modules/wf-react-component-library/build/styles/_colors.scss';

.po-error {
    border: 1px solid red;
}

.po-input {
    width: 100%;
    height: 30px;
    border-radius: 2px;
    color: #757575;
    border: 1px solid #E4E4E4;
    font-family: var(--fontCircular);
    font-weight: var(--font-weight-book);
    font-size: 1rem;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 5px;
    padding-right: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #FFFFFF;

    outline: none;
}

.po-input.item-details {
    border-color: $lag;
    &.error {
        border-color: $error;
    }
}

.item-details-row {
    text-align: left;
    & td {
        padding-left: 5px !important;
    }
}

.item-table-header {
    line-height: 25px;
    font-weight: bold;
    & td {
        padding-left: 5px !important;
    }
}

.item-details-col {
    padding: 5px 6px 5px !important;
}

.coo .wf-select {
    height: 30px !important;
    background-position: calc(100% - .5rem) calc(100% + .1rem) !important;
}

.coo.updated .wf-select {
    border: 1px solid $prime;
}

.coo .wf-form-element-container {
    padding: 0px !important;
    & select {
        font-size: 1.1em;
        padding-left: 5px;
        padding-right: 5px;
    }
}

.qty .wf-form-element-container {
    padding: 0px !important;
    & .wf-input {
        font-size: 1.1em;
        height: 30px !important;
        padding-left: 5px;
        padding-right: 5px;
    }
}

.price .wf-form-element-container {
    padding: 0px !important;
    & .wf-input {
        font-size: 1.1em;
        height: 30px !important;
        padding-left: 5px;
        padding-right: 5px;
    }
}

#cooler {
    font-size: 1rem;
    padding-left: 5px;
    padding-right: 35px;
}

.po-details-ship-to {
    padding-left: 10px;
    padding-bottom: 10px;
}

.item-details-col.qty.error .wf-input {
    border-color: $error;
}

.item-details-col.qty.updated .wf-input {
    border-color: $prime;
}

.item-details-col.price.error .wf-input {
    border-color: $error;
}

.item-details-col.price.updated .wf-input {
    border-color: $prime;
}