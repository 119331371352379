@import '../../node_modules/wf-react-component-library/build/styles/_colors.scss';

.edit-icon {
  height: 20px;
  width: 20px;
}

.flex-container-row.top-level.customers {
  justify-content: center;
  flex-wrap: wrap;
}

.flex-container-column.customer {
  padding: 10px 20px;
  color: $chard;
}

.error-modal {
  .modal {
    z-index: 9999 !important;
  }
  .overlay {
    z-index: 9998 !important;
  }
}