@import '../../node_modules/wf-react-component-library/build/styles/_colors.scss';
@import '../../node_modules/wf-react-component-library/build/styles/_icons.scss';

.App {
  text-align: center;
}

.App .page-header {
  padding: 20px;
  border-bottom: solid 2px $chard;
  justify-content: space-between;
}

.App .headline-serif {
  color: $chard;
}

.App .body-short-form {
  padding-left: 5px;
}

.flex-container-row.search-criteria {
  margin: 10px 20px 0px;
  border: 2px solid $chard;
  background-color: $white;
}

.flex-container-row.vendor-title {
  margin: 5px 20px 0px;
  background-color: #FFFFFF;
  font-family: Circular Pro,Arial,sans-serif;
  font-weight: 800;
  font-size: 1.2rem;
  color: $chard;
  align-items: flex-end;
  justify-content: space-between;
}

#multiselectContainerReact {
  max-width: 800px;
  min-width: 500px;
}

#multiselectContainerReact ._2iA8p44d0WZ-WqRBGcAuEV {
  width: 100%;
}

#multiselectContainerReact .chip {
  border-radius: 0px !important;
}

// display option list from the top down instead of bottom up
#multiselectContainerReact ._3vLmCG3bB3CM2hhAiQX1tN {
  top: 100% !important;
}

.DateInput_input.DateInput_input_1 {
  color: $lag;
  font-size: 1rem;
  font-weight: 700;
}

.DateInput_input {
  width: 80% !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #006F46 !important;
  border: 1px double #006F46 !important;
  color: #fff;
}

.CalendarDay__selected_span {
  background: #e4e4e4 !important;
  border: 1px double #e4e4e4 !important;
  color: #006F46 !important;
}



.flex-container-column.top-level {
  padding: 0px !important;
}

.toggle-search-bar {
  border: none;
  background-color: transparent;
  color: #004E36;
  cursor: pointer;
  outline: none;
  padding-right: 20px;
  font-size: .95rem;
  font-weight: 400;
  text-decoration: underline;
}
